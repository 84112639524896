import React, { ChangeEvent } from "react";

import {
  Box,
  Grid,
  Popover,
  FormControl,
  Typography,
  Modal,
  TextField,
  InputAdornment,
  Button

} from "@material-ui/core";

import AppHeader from "../../../../components/src/AppHeader.web";
import { ThemeProvider, styled } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";

type OverflowXProperty = "hidden";
type AppearanceStyle = 'none';
type TextTransformProperty = "capitalize"
type PositionStyle = 'absolute'
type PositionRelative = 'relative';
type FlexDirectionProperty = 'column';
type TextAlignProperty = 'center'

const gradientBackground = "linear-gradient(to right bottom, #2B3D7D, #39C6B3)";

import EmailAccountRegistrationController, {
  Props
} from "./../EmailAccountRegistrationController";
import Sidebar from "../../../../components/src/Sidebar.web";
import BasicTable from "../../../../components/src/BasicTable.web";
import BasicPagination from "../../../../components/src/BasicPagination.web";
import { closeIcon, deleteIcon, filterIcon, searchIcon, studentIcon, userGroupIcon } from "../assets";
import { theme } from "../SchoolAdmin/SchoolAdminUserGroups.web";
import ViewDetailsModal from "./ViewDetailsModal.web";

import { copyicon } from "../../../../components/src/assets";

interface OptionType {
  label: string;
  value: string;
}

interface SelectInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<{ name?: string; value: string }>) => void;
  options: OptionType[];
  placeholder?: string;
  error?: string;
  nonEditable?: boolean;
}

interface CommonButtonsProps {
  handleCancel: any;
  margin?: number;
  handleAdd?: () => void;
  editId?: string;
  nonEditable?: boolean;
  modal?: boolean;
  deleteId?: string;
  buttonText?: string;
}

const CancelBtn = styled(Button)({
  border: "1px solid #2B337D",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#000",
    width: "165px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    marginRight: "16px",
    cursor: "pointer",
    textTransform:"none",
    "@media (max-width: 600px)": {
      width: "100%"
    },
});

const DeleteConfirmationHeading = styled(Typography)({
  fontFamily: "Poppins",
    fontSize: "18px",
    color: "#202020",
    fontWeight: 400,
    lineHeight: '30px',
    marginBottom: "20px",
    textAlign: "center" as TextAlignProperty,
    "@media (max-width: 600px)": {
      width: "100%",
    },
});

const SubHeading = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "18px",
  color: "#3F526D",
  fontWeight: 400,
  lineHeight: '30px',
  width: "390px",
  textAlign: "center" as TextAlignProperty,
  "@media (max-width: 600px)": {
    fontSize: "15px",
    width:"300px"
  }

});

const Heading = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "22px",
  color: "#2B3D7D",
  fontWeight: 600,
  marginBottom: "8px",
  lineHeight: '40px',
  "@media (max-width: 600px)": {
    fontSize: "18px",
    textAlign: "center",
    width:"260px"
  },
  
});


const UserGroupName = styled(Box)({
  borderRadius: "6px",
  backgroundColor: "rgba(43, 61, 125, 0.08)",
  border: "1px solid rgba(43, 61, 125, 0.12)",
  width: "330px",
  height: "64px",
  fontFamily: "Poppins",
  fontSize: "18px",
  color: "#2C2C2E",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 500,
  marginBottom: "20px",
  "@media (max-width: 600px)": {
      width: "100%"
  },
});

const CmnBtn = styled(Button)({
  background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "181px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    textTransform:"none",
  "@media (max-width: 600px)": {
      width: "100%"
  },
});

const AddStudentBtnLayout = styled('button')(({ theme }) => ({
  background: "#2B3D7D",
  border: "none",
  borderRadius: "5px",
  color: "#fff",
  width: "181px",
  height: "50px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  textTransform: "none",
  "@media (max-width: 600px)": {
    width: "100%",
  },
  "&:disabled": {
    background: "#2B3D7DB9",  
    color: "#fff",
    cursor: "not-allowed",
    opacity: 0.6,
  },
}));

const CreateUserGrpBtn = styled('button')(({ theme }) => ({
  background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "181px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    "@media (max-width: 600px)": {
      width:"100%"
  },
}));


const CmnBtnContainer = styled(Box)({
      display: 'flex',
    justifyContent: 'end',
  "@media (max-width: 600px)": {
      width: "100%"
  },
});

const GrpDetailContainer = styled(Box)({
  display: "flex",
  width:"100%",
  gap:"40px",
"@media (max-width: 600px)": {
 flexDirection: "column"
},
});

const ResponsiveInput = styled('input')(({ theme }) => ({

  [theme.breakpoints.down('sm')]: {
    width: '100% !important',  
    fontSize: '14px',
  },
  
}));




interface SearchSectionProps {
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SelectInput: React.FC<SelectInputProps> = ({ label, name, value, onChange, options, placeholder, error, nonEditable }) => {
  return <Grid item lg={6} xs={12}>
    <FormControl fullWidth>
      <label style={webStyles.inputLabel}>{label}</label>
      <select
      
        name={name}
        value={value}
        defaultValue={value}
        style={error ? { ...webStyles.customSelect, border: "1px solid #fe575a" }
          : webStyles.customSelect}
        onChange={onChange}
        disabled={nonEditable}
      >
        {(placeholder && (value === '')) && <option key={placeholder} value={''}>
          {placeholder}
        </option>}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FormControl>
    <label style={webStyles.error}>{error}</label>
  </Grid>
};


export const SearchSection: React.FC<SearchSectionProps> = ({ handleSearch }) => {
  return <Box>
    <Box sx={webStyles.searchBoxx} marginTop={2}>
      <Box sx={webStyles.iconbox}>
        <img src={searchIcon} />
      </Box>
      <Box sx={webStyles.searchInput}>
        <ResponsiveInput data-test-id="search-input" style={webStyles.filterinput} placeholder="Search by name" onChange={handleSearch} />
      </Box>
    </Box>
  </Box>
}

export default class TeacherUserGroups extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleCopyLink=this.handleCopyLink.bind(this)
  }

  renderUserGroupForm = () => {
    const headerTitle = this.state.editId ? this.state.userGroup : "Create User Group"
    


    const studentColumns: { label: string; key: any; subKey: any }[] = [
      {
        label: "Student Name",
        key: "attributes",
        subKey: "full_name"
      },
      { label: "Class", key: "attributes", subKey: "classes" },
      { label: "Student Email ID", key: "attributes", subKey: "email" }
    ];

    const selectedStudentColumns: { label: string; key: any; subKey: any }[] = [
      {
        label: "Full Name",
        key: "attributes",
        subKey: "full_name"
      },
      { label: "Class", key: "attributes", subKey: "classes" },
      { label: "Student Email ID", key: "attributes", subKey: "email" },
      { label: "Password", key: "attributes", subKey: "password" },
      { label: "Date Created", key: "attributes", subKey: "date_created" }
    ];
    const action = <MoreVertIcon data-test-id="actionBtn" />;
    return (
      <>
        <AppHeader title={headerTitle} />
        <Box paddingX={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box paddingX={3} marginTop={5}>
                <form data-test-id="usergroup-form" onSubmit={this.handleFormSubmit} >
                  <Grid container spacing={3} style={webStyles.mt}>
                    <GrpDetailContainer>
                    <Grid item lg={6} xs={12}>
                      <label style={webStyles.inputLabel}>User Group Name*</label>
                      <input
                        data-test-id="usergroup-name-input"
                        type="text"
                        name="userGroup"
                        value={this.state.userGroup}
                        defaultValue={this.state.userGroup}
                        style={this.state.userGroupError ? { ...webStyles.custominput, border: "1px solid #fe575a" }
                          : webStyles.custominput}
                        onChange={this.handleChange}
                        placeholder='Enter User group name'
                        readOnly={this.state.nonEditable}
                      />
                      <label style={webStyles.error}>{this.state.userGroupError}</label>
                    </Grid>
                    <SelectInput
                      label="Class*"
                      name="class"
                    
                      value={this.state.nonEditable?this.state.classvalue:this.state.class}
                      onChange={this.handleChange}
                      options={this.state.classList}
                      placeholder="Select Class"
                      error={this.state.classError}
                      nonEditable={this.state.nonEditable}
                    />
                    </GrpDetailContainer>
                    <Grid item xs={12}>
                      <Box sx={webStyles.assignmentsHead} marginTop={5}>
                        <Box sx={webStyles.groupMemberContainer}>
                          <Typography variant="h4" component="h2" style={webStyles.headtext}>
                            Group Members
                          </Typography>
                          <Box sx={webStyles.innerInput}>
                            <Grid item xs={12}>
                              <SearchSection 
                              handleSearch={(e) => this.handleSearchSelectedStudents(e)} 
                              />
                              
                            </Grid>
                          
                        <AddStudentBtnLayout disabled={this.state.nonEditable} data-test-id="btnAddStudent" type="button" onClick={this.openAddStudentModal}>Add Student</AddStudentBtnLayout>
                      </Box>
                    </Box>
                      </Box>
                    </Grid>
                {
                      <>
                        <Grid item xs={12}>
                          <BasicTable
                            data-test-id="selected-students-table"
                            data={this.state.selectedStudents}
                            pageNo={this.state.pageNo}
                            columns={selectedStudentColumns}
                            handleClick={this.OpenStudentPopover}
                            action={action}
                          />
                            <Popover
                              open={Boolean(this.state.studentAnchorEl)}
                              anchorEl={this.state.studentAnchorEl}
                              onClose={this.CloseStudentPopover}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <div style={webStyles.dropdownmore}>
                              <button
                                  data-test-id="btnViewDetails"
                                  onMouseEnter={() => this.handleButtonHover(1)}
                                  onMouseLeave={() => this.handleButtonHover(null)}
                                  style={this.state.activeButton === 1 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
                                  onClick={() => {
                                  
                                    this.handelOpenView()
                                  }}
                                >
                                  <EventNoteOutlinedIcon style={webStyles.menumoreicon} />
                                  View Details
                              </button>
                                {!this.state.nonEditable && <button
                                  data-test-id="btnDeleteStudent"
                                  type="button"
                                  onMouseEnter={() => this.handleButtonHover(2)}
                                  onMouseLeave={() => this.handleButtonHover(null)}
                                  style={this.state.activeButton === 2 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
                                  onClick={() => {
                                    this.handleDeleteStudent()
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                  Delete Student
                                </button>}
                              </div>
                            </Popover>
                        </Grid>
                      </>
  } 
                    <Grid item xs={12}>
                      <CommonButtons handleCancel={this.handleCancel} margin={25} editId={this.state.editId} nonEditable={this.state.nonEditable} buttonText={(() => {
                        switch (true) {
                          case this.state.nonEditable && this.state.editId !== "": return "Edit Details";
                          case this.state.editId !== "" && !this.state.nonEditable: return "Save Details";
                          default: return "Create";
                        }
                      })()} />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Modal open={this.state.isVisibleStudentModal} onClose={this.hideStudentModal}>
          <Box sx={webStyles.modalsStyles}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  style={webStyles.title}
                >
                  Students
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <SearchSection handleSearch={(e) => this.handleSearchStudents(e)} />
              </Grid>
              <Grid item xs={12}>
                {this.state.studentsList &&
                  <BasicTable
                    data={this.state.studentsList}
                    pageNo={this.state.pageNo}
                    columns={studentColumns}
                    handleClick={() => { }}
                    action={null}
                    onRowSelect={this.handleSelectedStudents}
                    showCheckboxes={true}
                  />}
              </Grid>
              <Grid item xs={12}>
                <CommonButtons handleCancel={this.hideStudentModal} handleAdd={this.handleSubmitStudents} modal={this.state.isVisibleStudentModal} buttonText="Add" />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={this.state.studentSuccessModal} onClose={this.hideStudentSuccessModal}>
          <Box sx={webStyles.successModalsStyles}>
            <img style={webStyles.closeIcon} src={closeIcon} onClick={this.hideStudentSuccessModal} />
            <Box style={webStyles.modalContainer}>
              <Box style={webStyles.popupContent}>
                <img style={webStyles.image} src={studentIcon} />
                <Heading>
                  Group members added successfully!
                </Heading>
                <SubHeading>
                  Students have been successfully added to the user group.
                </SubHeading>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal data-test-id="addUserGroupSuccess" open={this.state.userGroupSuccessModal} onClose={this.hideUserGroupSuccessModal}>
          <Box sx={webStyles.successModalsStyles}>
            <img data-test-id="cancelIcon" style={webStyles.closeIcon} src={closeIcon} onClick={this.hideUserGroupSuccessModal} />
            <Box style={webStyles.modalContainer}>
              <Box style={webStyles.popupContent}>
                <img style={webStyles.image} src={userGroupIcon} />
                <Heading>
                  {this.state.userGroup} has been created!
                </Heading>

              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal open={this.state.openViewDetailsModal} onClose={this.handelCloseView}>
        <ViewDetailsModal
          handleCloseModal={this.handelCloseView}
          selectedStudent={this.state.selectedStudentvalue}
          Classdata={this.state.Classdata}
        />
      </Modal>
      </>
    )
  }

  renderDefaultView = () => {
    const headerTitle = "User Groups";
    const columns: { label: string; key: any; subKey: any }[] = [
      {
        label: "Group Name",
        key: "attributes",
        subKey: "user_group_name"
      },
      { label: "Class Code", key: "attributes", subKey: "group_alias" },
      { label: "Class", key: "attributes", subKey: "teacher_classes_name" },
  
      {
        label: "Number of Students",
        key: "attributes",
        subKey: "no_of_student"
      },
      { label: "Date Created", key: "attributes", subKey: "date_created" },
      { label: "Last Updated", key: "attributes", subKey: "last_updated" }
    ];
    const action = <MoreVertIcon data-test-id="actionBtn" />;
    return (
      <>
        <AppHeader title={headerTitle} />
        <Box sx={webStyles.searchBoxx} paddingX={'30px'} marginTop={5}>
          <Box sx={webStyles.searchiconbox}>
            <img src={searchIcon} />
          </Box>
          <Box sx={webStyles.searchInput}>
            <ResponsiveInput data-test-id="search-input" style={webStyles.filterinput} placeholder="Search by name" onChange={(e) => this.handleSearchUserGroups(e)} />
          </Box>
          <CreateUserGrpBtn data-test-id="btnAddGroup" onClick={this.openUserGroupForm}>Create user group</CreateUserGrpBtn>
        </Box>
        <Box paddingX={2} marginTop={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {this.state.userGroupsLoading ? (
                <Box style={webStyles.loaderStyle}>
                  <CircularProgress style={{ color: "#2B3D7D" }} />
                </Box>
              ) : (
                <>
                  <BasicTable
                    data={this.state.teacherUserGroups}
                    pageNo={this.state.pageNo}
                    columns={columns}
                    handleClick={this.handleClick}
                    action={action}
                  />
                  <Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <div style={webStyles.dropdownmore}>
                      <button
                        data-test-id="btnViewDetails"
                        onMouseEnter={() => this.handleButtonHover(1)}
                        onMouseLeave={() => this.handleButtonHover(null)}
                        style={this.state.activeButton === 1 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
                        onClick={() => this.handleViewDetails()}
                      >
                        <EventNoteOutlinedIcon style={webStyles.menumoreicon} />
                        View Details
                      </button>
                      <button
                        data-test-id="btnDeleteGroup"
                        type="button"
                        onMouseEnter={() => this.handleButtonHover(2)}
                        onMouseLeave={() => this.handleButtonHover(null)}
                        style={this.state.activeButton === 2 ? webStyles.moreBtnMenuActive : webStyles.moreBtnMenus}
                        onClick={() => {
                          this.handleDeleteUserGroups()
                        }}
                      >
                        <DeleteOutlineIcon />
                        Delete Group
                      </button>
                    </div>
                  </Popover>
                  {this.state.teacherUserGroups.length !== 0 && (
                    <Box style={webStyles.paginationBox}>
                      <BasicPagination
                        data-test-id="pagination"
                        handlePageChange={this.handlePageChange}
                        pageLimit={this.state.pageLimit}
                        pageNo={this.state.pageNo}
                      />
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <Modal data-test-id="delete-modal" open={this.state.showDeleteModal} onClose={this.hideDeleteModal}>
          <Box sx={webStyles.deleteModalsStyles}>
            <img style={webStyles.closeIcon} src={closeIcon} onClick={this.hideDeleteModal} />
            <Box style={webStyles.modalContainer}>
              <Box style={webStyles.popupContent}>
                <img style={webStyles.image} src={deleteIcon} />
                <DeleteConfirmationHeading>
                  Are you sure you want to delete this user group?
                </DeleteConfirmationHeading>
                <UserGroupName>
                  {this.state.deleteUserGroupName}
                </UserGroupName>
                <CommonButtons handleCancel={this.hideDeleteModal} handleAdd={this.deleteUserGroup} margin={5} deleteId={this.state.deleteId} buttonText="Delete" />
              </Box>
            </Box>
          </Box>
        </Modal>
      
      </>
    )
  }

  render() {
    const isUserGroupForm = this.state.view === "userGroupForm";
    let componentToRender;
    if (isUserGroupForm) {
      componentToRender = this.renderUserGroupForm();
    } else {
      componentToRender = this.renderDefaultView();
    }
    return (
      <ThemeProvider theme={theme}>
        <Grid container style={webStyles.container}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
          {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={webStyles.rightWrapper}>
            {componentToRender}
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

export const CommonButtons: React.FC<CommonButtonsProps> = ({ handleCancel, margin, handleAdd, editId, nonEditable, modal, deleteId, buttonText }) => {
  return <CmnBtnContainer marginTop={margin}>
    <CancelBtn data-test-id="btnCancel" type="button" onClick={handleCancel}>
      Cancel
    </CancelBtn>
    <CmnBtn data-test-id="btnAddGroup" type="submit" onClick={handleAdd}>
      {buttonText}
    </CmnBtn>
  </CmnBtnContainer>
}
const webStyles = {
  container: {
    height: "100%",
    overflowX: "hidden" as OverflowXProperty
  },
  rightWrapper: {
    height: "100%",
    overflowY: "scroll" as OverflowXProperty
  },
  paginationBox: {
    marginTop: "100px",
    marginBottom: "50px"
  },
  loaderStyle: {
    display: "flex",
    width: "100%",
    height: "400px",
    justifyContent: "center",
    alignItems: "center"
  },
  innerInput:{
    display:'flex',
    alignItems:"end",
    "@media (max-width: 600px)": {
      flexDirection:"column",
      gap:"10px",
      alignItems:"inherit",

  },
  },
  searchBoxx: {
    display: 'flex',
    gap: '4px',
    position: 'relative',
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      flexDirection:"column"
  },
  },
  assignmentsHead: {
    display: 'flex',
    gap: '4px',
    position: 'relative',
    alignItems: "end",
    justifyContent: "space-between",
  },
  filterinput: {
    width: '538px',
    height: '52px',
    borderRadius: '8px',
    border: '1px solid rgba(43,61,125,0.39)',
    background: '#fff',
    paddingLeft: '40px',
    fontFamily: "Poppins",
    "&::placeholder": {
      fontFamily: "Poppins",
      color: "#2b3d7d",
      fontSize: "16px"
    },
  },
  searchiconbox: {
    position: 'absolute',
    top: '13px',
    left: '40px',
  },
  searchInput: {
    display: "flex",
    gap: "10px"
  },
  filterIcon: {
    cursor: "pointer"
  },
  addUsersBtnstudent: {
    background: '#2B3D7D',
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "181px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer"
  },
  groupMemberContainer:{
    width:"100%",
  "@media (max-width: 600px)": {
      width:"100%"
  },
  },
  addUsersBtn: {
    background: gradientBackground,
    border: "none",
    borderRadius: "5px",
    color: "#fff",
    width: "181px",
    height: "50px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
    "@media (maxWidth: 600px)": {
      width:"100%"
  },
  },
  moreBtnMenus: {
    fontSize: '16px',
    color: '#1c1c1e',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    backgroundColor: '#fff',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    fontFamily: "Poppins"
  },
  moreBtnMenuActive: {
    background: gradientBackground,
    fontSize: '16px',
    color: '#fff',
    fontWeight: 400,
    display: 'flex',
    gap: '10px',
    height: "45px",
    alignItems: 'center',
    border: 'none',
    width: '100%',
    marginBottom: '0px',
    padding: '12px',
    cursor: 'pointer',
    fontFamily: "Poppins"
  },
  menumoreicon: {
    fontSize: '24px',
  },
  dropdownmore: {
    display: "block",
    gap: "10px",
    borderRadius: '6px',
    padding: '15px 0',
    width: "max-content",
  },
  mt: {
    marginTop: "15px",
  },
  inputLabel: {
    fontFamily: "Poppins"
  },
  customSelect: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    appearance: "none" as AppearanceStyle,
    background: `url("https://cdn-icons-png.flaticon.com/512/60/60995.png")`,
    backgroundSize: "4%",
    backgroundPosition: "right .75rem center",
    backgroundRepeat: "no-repeat",
    paddingRight: "15px",
    lineHeight: "30px",
    fontFamily: "Poppins"
  },
  custominput: {
    borderRadius: "8px",
    border: "1px solid #C0C0C0",
    padding: "8px 15px",
    marginTop: "10px",
    display: "block",
    width: "100%",
    minHeight: "50px",
    fontSize: "16px",
    fontFamily: "Poppins",
  },
  error: {
    color: "red",
    fontSize: "12px",
    marginTop: "10px",
    display: "block",
    fontFamily: "Poppins"
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'end'
  },
  cancelsBtn: {
  },
  headtext: {
    fontSize: "24px",
    color: "#2B3D7D",
    letterSpacing: "0.32px",
    margin: 0,
    fontFamily: "Poppins",
    textTransform: 'capitalize' as TextTransformProperty,
    fontWeight: 600
  },
  modalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    height: "480px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 600,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    "@media (max-width: 600px)": {
      width: "70%",
      minWidth: 0
  },
  },
  successModalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    height: 'auto',
    maxHeight:'70vh',
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 440,
    width: '100%',
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    "@media (max-width: 600px)": {
      width: "70%",
      overflow:"hidden"
  },
  },
  deleteModalsStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "12px",
    minHeight: "311px",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 440,
    width: '100%',
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    "@media (max-width: 600px)": {
      width: "70%"
  },
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "22px",
    color: "#000000"
  },
  iconbox: {
    position: 'absolute',
    top: '13px',
    left: '10px',
  },
  closeIcon: {
    position: "absolute" as PositionStyle,
    right: "20px",
    top: "20px",
    cursor: "pointer"
  },
  popupContent: {
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    alignItems: "center",
  },
  heading: {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#2B3D7D",
    fontWeight: 600,
    marginBottom: "8px",
    lineHeight: '40px',
    "@media (max-width: 600px)": {
      fontSize: "18px",
      textAlign: "center",
      width:"260px"
    },
  },
  subheading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#3F526D",
    fontWeight: 400,
    lineHeight: '30px',
    width: "390px",
    textAlign: "center" as TextAlignProperty,
    "@media (max-width: 600px)": {
      fontSize: "15px",
      width:"300px"
    }
  },
  deleteheading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#202020",
    fontWeight: 400,
    lineHeight: '30px',
    marginBottom: "20px",
    textAlign: "center" as TextAlignProperty,
    "@media (max-width: 600px)": {
      width: "80%"
    },
  },
  userGroupName: {
    borderRadius: "6px",
    backgroundColor: "rgba(43, 61, 125, 0.08)",
    border: "1px solid rgba(43, 61, 125, 0.12)",
    width: "330px",
    height: "64px",
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    marginBottom: "20px",
  },
  image: {
    marginBottom: "15px",
  },
  modalContainer: {
    position: "relative" as PositionRelative,
  },
  link: {
    color: "#2B3D7D",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius:'18px',
    marginTop:'45px',
    "&:hover": {
      borderBottom: "none",
    },
  },
  linkBox: {
    backgroundColor: "#f9f8fd",
    borderRadius: "4px",
    width: '90%',
    minHeight: '31px',
    "& .MuiInputBase-root": {
      color: "#2B3D7D",
      "& fieldset": {
        border: "0.5px solid #c0c0c0",
      },
      "&:hover fieldset": {
        borderColor: "rgb(192, 192, 192)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(192, 192, 192)",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    cursor: "pointer",
  },
};