import React from "react";
import {
  Box,
  Grid
} from "@material-ui/core";
import CatalogueController, { Props } from "../CatalogueController";
import Sidebar from "../../../../components/src/Sidebar.web";
import AppHeaderWeb from "../../../../components/src/AppHeader.web";
import StudentClassList from "./components/Classes/StudentClassList.web";
import SelectedClass from "../SchoolAdmin/components/SelectedClass/SelectedClass.web";
import StudentSelectedPractice from "./components/SelectedPractice/StudentSelectedPractice.web";
import LessonPreviewPage from "../Teachers/components/TeacherSelectedPractice/LessonPreviewPage.web";

export default class StudentCatalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    this.selectedClass = this.selectedClass.bind(this);
    this.selectedPractice= this.selectedPractice.bind(this);
    this.redirect=this.redirect.bind(this);
    this.handleSearchInputTable = this.handleSearchInputTable.bind(this);
    this.handlePracticePageChange = this.handlePracticePageChange.bind(this);
    this.handleSearchStudentClass=this.handleSearchStudentClass.bind(this)
    this.selectedLessons=this.selectedLessons.bind(this);
    this.handleOpenAddFolderModal = this.handleOpenAddFolderModal.bind(this);
    this.removeCommentIcon=this.removeCommentIcon.bind(this);
    this.handleOpenAnnotationConfirmation=this.handleOpenAnnotationConfirmation.bind(this)
    this.handleCloseAnnotationConfirmation=this.handleCloseAnnotationConfirmation.bind(this)
    this.handleHighlightedTextData=this.handleHighlightedTextData.bind(this)
    this.applyHighlightDirectly=this.applyHighlightDirectly.bind(this)
    this.redoHighlight=this.redoHighlight.bind(this)
    this.handleCloseDeleteSuccess=this.handleCloseDeleteSuccess.bind(this)
    this.getCommentData=this.getCommentData.bind(this)
    this.handleOpenSuccessPopup=this.handleOpenSuccessPopup.bind(this);
    this.handleCloseSuccessPopup=this.handleCloseSuccessPopup.bind(this);
    this.handleOpenAnnotationConfirmation=this.handleOpenAnnotationConfirmation.bind(this)
    this.handleCloseAnnotationConfirmation=this.handleCloseAnnotationConfirmation.bind(this)
    this.handleHighlightText=this.handleHighlightText.bind(this)
    this.nextStage=this.nextStage.bind(this);
    this.prevStage=this.prevStage.bind(this);
    this.redirectForTeacherRole = this.redirectForTeacherRole.bind(this)
    this.downloadPractice=this.downloadPractice.bind(this)
    this.handleShowNotesIcon = this.handleShowNotesIcon.bind(this);
    this.handleOpenDeleteModal=this.handleOpenDeleteModal.bind(this);
    this.deleteStudentLibrary = this.deleteStudentLibrary.bind(this);
    this.handleCloseDeleteModal=this.handleCloseDeleteModal.bind(this)
  }
  renderSwitch(selectedOption: string) {
    switch (selectedOption) {
        case "Selected Class":
            return (
              <SelectedClass
                callback={this.selectedPractice}
                currentLocation={this.state.currentLocation}
                locations={this.locations}
                redirect={this.redirect}
                role={this.state.role}
              />
            );
            case "Selected Practice":
        return (
          <StudentSelectedPractice
            currentLocation={this.state.currentLocation}
            locations={this.locations}
            practiceData={this.state.sharedData}
            tableSearchInput={this.state.tableSearchInput}
            handleSearchInputTable={this.handleSearchInputTable}
            redirect={this.redirect}
            loading={this.state.loading}
            handlePracticePageChange={this.handlePracticePageChange}
            pageLimitPractice={this.state.pageLimitPractice}
            pageNoPractice={this.state.pageNoPractice}
            handleOpenNoMobileModal={this.handleOpenNoMobileModal}
            handleCloseNoMobileModal={this.handleCloseNoMobileModal}
            openNoMobileModal={this.state.openNoMobileModal}
            selectedLessons={this.selectedLessons}
            editPractice={this.editPractice}
            handleOpenAddFolderModal={this.handleOpenAddFolderModal}
            downloadLesson={this.downloadPractice}
            notesList={this.state.notesList}
            handleShowNotesIcon = {this.handleShowNotesIcon}
            handleOpenDeleteModal={this.handleOpenDeleteModal}
            handleCloseDeleteModal={this.handleCloseDeleteModal}
            deleteStudentLibrary={this.deleteStudentLibrary}
            openDeleteConfirmationModal={this.state.openDeleteConfirmationModal}
            token={this.state.token}
            getLessonFolderListByClass={this.getLessonFolderListByClass}
            addingModal={this.state.addingModal}
            uploadModal={this.state.uploadModal}
            setAddingModal={this.setAddingModal}
            setUploadModal={this.setUploadModal}
            uploadLessonQuiz={this.uploadLessonQuiz}
            typeError={this.state.typeError}
            setTypeErrorEmpty={this.setTypeErrorEmpty}
            uploadLoading={this.state.uploadLoading}
          />
        );
        case "Selected Lesson":
          return(
            <LessonPreviewPage
            data-test-id = "lessonPreview"
            currentLocation={this.state.currentLocation}
            locations={this.locations}
            redirect={this.redirectForTeacherRole}
            stageType={this.state.stageType}
            pageStageLimit={this.state.pageStageLimit}
            nextStage={this.nextStage}
            prevStage={this.prevStage}
            pageNoStage={this.state.pageNoStage}
            currentStage={this.state.currentStage}
            loading={this.state.loading}
            isAnnotate={this.state.isAnnotate}
            handleHighlightText={this.handleHighlightText}
            handleOpenAnnotationConfirmation={this.handleOpenAnnotationConfirmation}
            handleCloseAnnotationConfirmation={this.handleCloseAnnotationConfirmation}
            openAnnotationConfirmation={this.state.openAnnotationConfirmation}
            handleCloseSuccessPopup={this.handleCloseSuccessPopup}
            handleOpenSuccessPopup={this.handleOpenSuccessPopup}
            openMoveSuccessPopup={this.state.openMoveSuccessPopup}
            highlights={this.state.highlights}
            highlightedData={this.state.highlightedData}
            handleHighlightedTextData={this.handleHighlightedTextData}
            redoHighlight={this.redoHighlight}
            applyHighlightDirectly={this.applyHighlightDirectly}
            uploading={this.state.uploading}
            commentData={this.state.commentData}
            getCommentData={this.getCommentData}
            removeCommentIcon={this.removeCommentIcon}
            stagesData={this.state.stagesData}
            stages={this.state.stages}
            handleSaveClick={this.handleSaveClick}
            checkSignIn={this.handleAuthClick}
            handleCloseGdriveModal={this.handleCloseGdriveModal}
            openFileUploadSuccess={this.state.openFileUploadSuccess}
            />
          )
        default:
        return (
            <StudentClassList
            searchInput={this.state.searchInput}
            classList={this.state.classes}
            handleSearchInput={this.handleSearchStudentClass}
            callback={this.selectedClass}
            pageLimit={this.state.pageNoTeacherClassesLimit}
            pageNo={this.state.pageNoTeacherClasses}
            handleTeacherClassPageChange={this.handleTeacherClassPageChange}
            loading={this.state.loading}
            navigation={this.props.navigation}
          />
        );
    }
  }

  render() {
    return (
      <Box>
        <Grid   
        container={this.state.isSmallScreen ? false : true} 
        style={{ overflowX: "hidden", height: "100%" }}>
          <Grid item {...(!this.state.isSmallScreen && { xs: 2 })}>
            {!this.state.isSmallScreen && <Sidebar />}
          </Grid>
          <Grid item {...(!this.state.isSmallScreen && { xs: 10 })} style={{ overflowY: "scroll", height: "100%" }}>
            <Grid item md={12}>
              <AppHeaderWeb title={"Library"} />
            </Grid>
            <Grid item md={12}>
              {this.renderSwitch(this.state.render)}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}
