import React, { useState } from "react";
import CustomBreadcrumb from "../../../../../../components/src/CustomBreadcrumb.web";
import { Box, Grid, Typography,useMediaQuery, Button, Modal, styled, LinearProgress } from "@material-ui/core";
import SearchSection from "../../../../../../components/src/SearchSection.web";
import DataTable from "../../DataTable.web";
import Loader from "../../../../../../components/src/Loader.web";
import BasicPagination from "../../../../../../components/src/BasicPagination.web";
import { makeStyles } from "@material-ui/core/styles";
import { imageUpload, importLesson, redCross, uploadImage } from "../../../../../CfCourseCreation23/src/assets";
import CloseIcon from "@material-ui/icons/Close";
import GenericButton from "../../../../../../components/src/GenericButton.web";
import CircularProgress from '@material-ui/core/CircularProgress';
interface LessonData {
  type: string;
  id: string;
  attributes: {
    lesson_markup_tools_attributes: MarkupTool[];
    [key:string]:any
  };
}

interface MarkupTool {
  [key: string]: any;
  stage_number: string;
}

interface MarkupToolAS {
  [key: string]: any;
  attributes:{
    [key: string]: any;
    stage_number: string;
  }
}

interface AssignmentDataIn {
  type: string;
  id: string;
  attributes: {
    assignment_markup_tools: {data:MarkupToolAS[]};
    [key: string]: any;
  };
}

const useStyles = makeStyles({
  btnLayout: {
    marginBottom: "50px",
    display: "flex",
    justifyContent: "end",
  },
  noRecords: {
    fontFamily: "Poppins",
    textAlign: "center",
    paddingTop: "35px",
    width: "100%"
  },
  breadCrumb:{
    paddingLeft: "35px",
    marginTop: "30px"
  },
  breadCrumbTop: {
    display: 'flex',
    alignItems: 'end',
    marginTop: "30px",
    paddingLeft: "35px",
    paddingRight: "35px",
    justifyContent: 'space-between',
  },
  uploadImg: {
    marginRight: '10px'
  },
  uploadBtn: {
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Poppins",
    textTransform: "none",
    borderRadius: "10px",
    color: "#3F526D",
    border: "1px solid #3F526D",
    marginBottom: "15px",
    cursor: 'pointer',
    padding: "10px 20px 10px 20px",
  },
  tableLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px",
    marginTop: "10px"
   },
   searchLayout:{
    paddingLeft: "35px",
    paddingRight: "35px",
    marginBottom: "20px"
   },
});

const webStyles = {
  finalUploadBtn: {
    backgroundImage:
      "linear-gradient(to right bottom, rgba(0,10,77,1) 0%, rgba(21,8,112,1) 36%, rgba(41,142,139,1) 100%)",
    position: 'relative' as 'relative',
    padding: "12px",
    textTransform: "none" as "none",
    fontFamily: "Poppins",
    color: "#FFF",
    fontSize: "18px",
    width: "150px",
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: "hidden",
    borderRadius: "10px",
 },
 normalUploadBtn: {
    width: "150px",
    height: '60px',
    borderRadius: "10px",
    padding: "12px",
    fontFamily: "Poppins",
    textTransform: "none" as "none",
    border: "1px solid #ccc",
    color: "#ccc",
    marginRight: "20px",
    fontSize: "18px",
    background: '#FFF',
 }
}
const StudentSelectedPractice = (props: any) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [file, setFile] = useState<File | null>(null);

  const handleOpenAddingModal = () => {
    props.setAddingModal(true)
  };

  const handleCloseAddingModal = () => {
    props.setAddingModal(false)
    props.setTypeErrorEmpty();
  }

  const handleUploadModal = () => {
    props.setUploadModal(false)
    props.setTypeErrorEmpty();
  };

  const isValidJsonFile = (file: File): boolean => {
    return file.type === "application/json" || file.name.endsWith('.json');
  };

  const processFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        let jsonData = JSON.parse(event.target.result) as LessonData|AssignmentDataIn;
        const jsonString = JSON.stringify(jsonData);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const filee = new File([blob], file.name, { type: 'application/json' });
        setFile(filee)

        props.setUploadModal(true)

      }
    };
    reader.readAsText(file);
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setTypeErrorEmpty();
    const selectedFile = event.target.files?.[0] || null;

    if (selectedFile && isValidJsonFile(selectedFile)) {
      processFile(selectedFile);
    } else {
      alert("Please upload a valid JSON file.");
    }
  
    event.target.value = "";
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const selectedFile = event?.dataTransfer?.files?.[0] || null;

    if (selectedFile && isValidJsonFile(selectedFile)) {
      processFile(selectedFile);
    } else {
      alert("Please upload a valid JSON file.");
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDeleteItem = () => {
    setFile(null);
    props.setUploadModal(false);
  };

  const addingModalRendering = () => {
    return (
      <>
        <ModalFirstStyle data-test-id="dropFunctionTestIf">
          <Box className="closeIcons">
            <CloseIcon data-test-id='closehandleCloseAddingModal' onClick={handleCloseAddingModal} />
          </Box>
          <Box className="notificationHeading">
            <Typography className="uploadFile">Upload a File</Typography>
          </Box>
          <Grid container spacing={3} style={{display:"flex", justifyContent:"center"}}>
            <Grid item xs={12} sm={6} md={6} lg={6} style={{maxWidth:"100%",flexBasis:"auto"}}>
              <Box
                data-test-id="handleDropTestId"
                className="dragStyle"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <img
                  src={imageUpload}
                />
                <label htmlFor="fileInput" className="uploads">
                  <Typography className="chosefileStyle">
                    Drag & Drop a file or{" "}
                    <span className="browserStyle">Browse</span>
                  </Typography>
                  <Typography className="supportFormat">
                    Supported format JSON
                  </Typography>
                </label>
                <input
                  data-test-id="handleFileChangeTestId"
                  id="fileInput"
                  type="file"
                  accept=".json,application/json"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Box>
            </Grid>
          </Grid>
        </ModalFirstStyle>
      </>
    );
  };

  const uploadedDocumentRender = () => {
    return (
      <>
        <UploadModalStyle>
          <Box className="closeIcons">
            <CloseIcon data-test-id='closeUploadModal' onClick={handleUploadModal} />
          </Box>
          <Box className="notificationHeading">
            <Typography className="uploadFile">Upload a File</Typography>
          </Box>
          <Box
            className="dragStyle"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <img
              src={imageUpload}
            />
            <label htmlFor="fileInput" className="uploads">
              <Typography className="chosefileStyle">
                Drag & Drop a file or{" "}
                <span className="browserStyle">Browse</span>
              </Typography>
              <Typography className="supportFormat">
                Supported format JSON
              </Typography>
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".json,application/json"
              onChange={handleFileChange}
              multiple 
              style={{ display: "none" }}
            />
          </Box>

          {file ? (
            <Box className="uploadImage">
              <img src={importLesson} className="imageText"/>
              <Box className="progessBar">
                <Typography style={{marginBottom:"17px"}}>{file.name}</Typography>
                <LinearProgress
                  color="primary"
                  variant="determinate"
                  value={100}
                  className="styleProgressBar"
                />
              </Box>
              <Box style={{marginTop:"15px",cursor: 'pointer'}}>
                <img src={redCross} style={{marginLeft:"15px"}}
                  data-test-id = "handleDeleteItemTestId"
                  onClick={handleDeleteItem}
                />
                <Typography className="percentage">100%</Typography>
              </Box>
            </Box>
          ) : (
            ""
          )}
          {!!props.typeError && (
                <Typography 
                data-test-id = "formatErr"
                style={{ 
                  color: 'red',
                  marginBottom: '60px', 
                  textAlign: "center", 
                  fontFamily:"Poppins" 
                  }}>
                  {props.typeError}
                </Typography>
              )}
            <Box className={classes.btnLayout}>
          <GenericButton
           data-test-id="cancelApiTest"
            type="NORMAL"
            label="Cancel"
            handleClick={handleUploadModal}
            customStyle={{ width: "150px" }}
          />
          <Box>
            <FinalUploadButton
              typeError={props.typeError}
              uploadLoading={props.uploadLoading}
              uploadLessonQuiz={props.uploadLessonQuiz}
              file={file}
              element={props.uploadLoading ? <CircularProgress style={{color: "#2B3D7D"}} /> : "Upload"}
            />
          </Box>
        </Box>
        </UploadModalStyle>
      </>
    );
  };

  interface ButtonProps {
    typeError: boolean;
    uploadLoading: boolean;
    uploadLessonQuiz: (file: File | null) => void;
    element: JSX.Element | string
  }
  
  interface FileProps {
    file: File | null;
  }

  const FinalUploadButton: React.FC<ButtonProps & FileProps> = (props) => (
    <button
      style={!props.typeError ? webStyles.finalUploadBtn : webStyles.normalUploadBtn}
      disabled={props.typeError}
      data-test-id="uploadApiTest"
      onClick={() => props.uploadLessonQuiz(props.file)}
    >
      {props.element}
    </button>
  );

  const {
    locations,
    currentLocation,
    tableSearchInput,
    handleSearchInputTable,
    redirect,
    practiceData,
    loading,
    handlePracticePageChange,
    pageLimitPractice,
    pageNoPractice,
    handleCloseNoMobileModal,
    openNoMobileModal,
    selectedLessons,
    editPractice,
    handleOpenAddFolderModal,
    downloadLesson,
    notesList,
    handleOpenDeleteModal,
    handleShowNotesIcon,
    handleCloseDeleteModal,
    deleteStudentLibrary,
    openDeleteConfirmationModal,
    handleOpenNoMobileModal
  } = props;
  return (
    <>
      <Box className={classes.breadCrumbTop}>
      <Modal
        open={props.addingModal}
        onClose={handleCloseAddingModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {addingModalRendering()}
      </Modal>
      <Modal
        open={props.uploadModal}
        onClose={handleUploadModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {uploadedDocumentRender()}
      </Modal>
        <CustomBreadcrumb
          locations={locations}
          destination={currentLocation}
          redirect={redirect}
        />
        
        <Button
          data-test-id = "uploadTestId"
          variant="outlined"
          className={classes.uploadBtn}
          // onMouseEnter={(e) => (uploadImageRef.current.src = uploadImageActive)}
          // onMouseLeave={(e) => (uploadImageRef.current.src = uploadImage)}
          onClick={handleOpenAddingModal}
        >
          <img
            // ref={uploadImageRef}
            src={uploadImage}
            className={classes.uploadImg}
          />
          Upload
        </Button>




      </Box>
      <Grid container={isSmallScreen ? false : true}>
        <Grid item md={12}>
          <Box className={classes.searchLayout}>
            <SearchSection
              searchInput={tableSearchInput}
              handleSearchInput={handleSearchInputTable}
              placeholder={"Search by name"}
            />
          </Box>
        </Grid>
        {loading ? (
          <Loader loading />
        ) : (
          <>
            {!practiceData.data.length ? (
              <Typography className={classes.noRecords}>
                No records found
              </Typography>
            ) : (
              <>
                <Grid item md={12}>
                  <Box className={classes.tableLayout}>
                    <DataTable
                      practiceData={practiceData}
                      handleOpenNoMobileModal={handleOpenNoMobileModal}
                      handleCloseNoMobileModal={handleCloseNoMobileModal}
                      openNoMobileModal={openNoMobileModal}
                      selectedLessons={selectedLessons}
                      editPractice={editPractice}
                      handleOpenAddFolderModal={handleOpenAddFolderModal}
                      downloadLesson={downloadLesson}
                      notesList={notesList}
                      handleShowNotesIcon={handleShowNotesIcon}
                      handleOpenDeleteModal = {handleOpenDeleteModal}
                      handleCloseDeleteModal={handleCloseDeleteModal}
                      deleteStudentLibrary={deleteStudentLibrary}
                      openDeleteConfirmationModal={openDeleteConfirmationModal}
                    />
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Box className={classes.breadCrumb}>
                    <BasicPagination
                      handlePageChange={handlePracticePageChange}
                      pageLimit={pageLimitPractice}
                      pageNo={pageNoPractice}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default StudentSelectedPractice;

const ModalFirstStyle = styled(Box)({
  width:"414px",
  position: "relative",
  padding: "20px",
  borderRadius: "15px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height:"413px",
  marginRight: "40px",
  backgroundColor: "#f2f8ff",
  "& .notificationHeading": {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  "& .uploadFile": {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "20px",
    color: "#2B3D7D",
  },
  "& .closeIcons": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .chosefileStyle": {
    fontFamily: "Poppins",
    fontSize: "18px",
    textAlign: "center",
    margin: "15px 0px 0px 0px",
  },
  "& .browserStyle": {
    cursor: 'pointer',
    textDecoration: "underline",
    fontFamily: "Poppins",
    textAlign: "center",
  },
  "& .dragStyle": {
    height: "267px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid lightBlue",
    borderStyle:"dashed",
    borderRadius: "10px",
  },
  "& .supportFormat": {
    fontFamily: "Poppins",
    color:"747474",
    textAlign: "center",
    margin:"15px 0px",
    fontSize:"14px",
  },
});

const UploadModalStyle = styled(Box)({
  width: "50%",
  maxWidth: "70%",
  overflowY: "scroll",
  height: "80%",
  position: "absolute",
  top: "50%",
  borderRadius: "15px",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "50px",
  "& .notificationHeading": {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "20px",
  },
  "& .uploadFile": {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "20px",
    color: "#2B3D7D",
  },
  "& .closeIcons": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .dragStyle": {
    height: "267px",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid lightBlue",
    borderStyle:"dashed"
  },
  "& .chosefileStyle": {
    fontSize: "18px",
    fontFamily: "Poppins",
    textAlign: "center",
    margin: "15px 0px 0px 0px",
  },
  "& .browserStyle": {
    cursor: 'pointer',
    textAlign: "center",
    fontFamily: "Poppins",
    textDecoration: "underline",
  },
  "& .supportFormat": {
    fontFamily: "Poppins",
    color:"747474",
    margin:"15px 0px",
    fontSize:"14px",
    textAlign: "center",
  },
  "& .uploadImage": {
    display: "flex",
    alignItems: "center",
    marginTop: "60px",
    justifyContent: "space-between",
  },
  "& .styleProgressBar": {
    borderRadius: "10px",
    height: "10px",
  },
  "& .cancleIcon": {
    color: "red",
  },
  "& .imageText": {
    width: "100px",
    height: "100px",
    margin: "10px",
  },
  "& .progessBar": {
    margin: "10px",
    flex: 1,
  },
  "& .percentage": {
    color: "lightGreen",
    cursor: 'pointer'
  },

  "& .cancleBtn": {
    width: "120px",
    margin: "10px",
    textTransform: "capitalize",
    fontSize: "15px",
    color: "#2b3e7d",
    border: "1px solid #2b3e7d",
  },
  "& .nxtBtn": {
    color: "white",
    fontSize: "15px",
    width: "120px",
    backgroundColor: "#2b3e7d",
    margin: "10px",
  },
  "& .buttonStyle": {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px",
  },
});
